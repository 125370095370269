import dayjs from 'dayjs'
import { rem } from 'design'
import {
  Text,
  Lockup,
  IconQuality,
  IconValue,
  IconFast,
} from '@butcherbox/freezer'
import { sharedIconProps } from '~/components/ModuleMemberPerks/ModuleMemberPerks'
import { makeSafeVariants } from 'design/image-utils'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import { useProductOfferLander } from '~/cms/hooks/useProductOfferLander'
import makeRichTextOptions from '~/cms/rich-text'
import { CMS } from '~/cms/types'
import ModuleMemberPerks from '~/components/ModuleMemberPerks/ModuleMemberPerks'
import ModuleOptIn from '~/components/ModuleOptIn/ModuleOptIn'
import ModuleSocialCards from '~/components/ModuleSocialCards/ModuleSocialCards'
import ModuleText from '~/components/ModuleText/ModuleText'
import { EmailSkipExperimentProvider } from '~/experiments/JOIN-440-462-email-skip/EmailSkipExperimentContext'
import { useAcquisitonBannerContent } from '~/hooks/useBannerContent'
import SEO from '~/layouts/seo'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import setLanderCookies from '~/utils/lander'
import { cleanJsonStrings } from '~/utils/unicode'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'

const FORM_SECTION_HEIGHT = 576

export interface LanderOfferProduct {
  pageData: CMS.LandingPage<CMS.ProductOfferLander>
  shouldIndex: boolean
  uniquePageId: string
}

/**
 * Render a Product Offer Lander for an offer
 */
export default function LanderOfferProduct(
  props: CMS.StaticPage<LanderOfferProduct>
) {
  const { pageData, shouldIndex, uniquePageId } = props.pageContext || {}

  const {
    coupon,
    moduleOptInTop,
    offer,
    fullWidthTextModule,
    moduleOptInBottom,
    socialCards,
  } = useProductOfferLander(pageData.content ?? ({} as CMS.ProductOfferLander))

  const queryResult = cleanJsonStrings(useStaticQuery(query))
  const { setContent } = useAcquisitonBannerContent()
  const isSpecialOfferG = uniquePageId === 'product-special-offer-g'

  const [redirectEnabled] = useOptimizelyFeature(
    'join-741__special-offer-redirect'
  )

  const shouldRedirect = redirectEnabled && isSpecialOfferG

  if (shouldRedirect) {
    const searchParams = window.location.search || ''
    window.location.replace(
      `https://shop.butcherbox.com/partners/affiliate-special-offer${searchParams}`
    )
  }

  // save utm params as cookies
  React.useEffect(() => {
    setLanderCookies({
      lander: uniquePageId,
      offer: offer.id
        ? {
            id: offer.id,
            expiry: dayjs(offer.expiration).endOf('day'),
          }
        : undefined,
      offerText: offer?.bannerText,
      queryDefaults: {
        utm_campaign: offer.utmCampaign,
      },
      couponCode: coupon.code,
    })

    setContent(
      <Text component="p" data-what="banner-text" variant="H3Bold">
        <span style={{ textTransform: 'uppercase' }}>
          {offer?.bannerText || 'Special offer for new members'}
        </span>
      </Text>
    )
  }, [setContent, uniquePageId, offer, coupon])

  return (
    <>
      {shouldRedirect ? (
        <LoadingSpinner />
      ) : (
        <UnauthenticatedLayout
          disablePublicity
          SEO={
            <SEO
              image={pageData?.seo?.image?.fixed?.src}
              pathname={props.location.pathname}
            />
          }
          simpleHeader
        >
          <EmailSkipExperimentProvider isGatedLander={shouldIndex}>
            {moduleOptInTop && (
              <ModuleOptIn
                backgroundColor="bb.ivory"
                body={moduleOptInTop.body}
                cta={moduleOptInTop.cta}
                dataWhere="optin_top"
                disclaimers={moduleOptInTop?.disclaimers || []}
                header={moduleOptInTop.header}
                headerColor="slate"
                heading="h1"
                id="lander-product-offer-optin"
                imgAlt={moduleOptInTop?.imageAlt}
                imgOverlayBackground={makeSafeVariants({
                  imageMobile: moduleOptInTop?.imageOverlayBackgroundMobile,
                  imageDesktop: moduleOptInTop?.imageOverlayBackgroundDesktop,
                })}
                shouldCollectEmail={shouldIndex}
                variant="Card Overlay"
              />
            )}

            {fullWidthTextModule?.header || fullWidthTextModule?.body?.raw ? (
              <ModuleText aria-labelledby="lander-special-offer-text">
                <Lockup textAlign="center">
                  <IconQuality
                    display="inlineBlock"
                    size="regular"
                    variant="duo"
                  />
                  {fullWidthTextModule?.header ? (
                    <Text
                      component="h2"
                      id="lander-special-offer-text"
                      variant="Subhead1"
                    >
                      {fullWidthTextModule?.header}
                    </Text>
                  ) : null}
                  {fullWidthTextModule?.body?.raw ? (
                    <Text variant="Body1Regular">
                      {renderRichText(
                        fullWidthTextModule?.body,
                        makeRichTextOptions()
                      )}
                    </Text>
                  ) : null}
                </Lockup>
              </ModuleText>
            ) : null}
            <ModuleMemberPerks
              as="section"
              bgFluid={queryResult.memberPerksImage.childImageSharp.fluid}
              header="What is ButcherBox?"
              id="lander-product-offer-member-perks"
              perks={[
                {
                  renderIcon: () => <IconQuality {...sharedIconProps} />,
                  header: 'quality',
                  body:
                    "We're committed to premium meat: 100% grass-fed beef, free-range organic chicken, pork raised crate-free, and wild-caught seafood.",
                },
                {
                  renderIcon: () => <IconValue {...sharedIconProps} />,
                  header: 'value',
                  body:
                    "Our goal is to make high-quality meat accessible to as many people as possible. We're able to deliver you the best products for less than $6 per meal. And shipping is free!",
                },
                {
                  renderIcon: () => <IconFast {...sharedIconProps} />,

                  header: 'convenience',
                  body:
                    'Get our meat delivered, for free, right to your door. Each box perfectly packed and portioned for your needs.',
                },
              ]}
              subheader="Better Meat for a Better You"
            />
            {moduleOptInBottom && (
              <ModuleOptIn
                backgroundColor="white"
                body={moduleOptInBottom.body}
                cta={moduleOptInBottom.cta}
                dataWhere="optin_bottom"
                disclaimers={moduleOptInBottom?.disclaimers || []}
                header={moduleOptInBottom.header}
                headerColor="slate"
                heading="h2"
                height={{ base: 'auto', tablet: rem(FORM_SECTION_HEIGHT) }}
                id="lander-product-offer-optin"
                img={moduleOptInBottom?.imageSplitCard?.fluid}
                imgAlt={moduleOptInBottom.imageAlt}
                shouldCollectEmail={shouldIndex}
                variant="Card"
              />
            )}
            {socialCards && (
              <ModuleSocialCards
                as="section"
                cards={socialCards}
                id="lander-product-offer-social-cards"
              />
            )}
          </EmailSkipExperimentProvider>
        </UnauthenticatedLayout>
      )}
    </>
  )
}

const query = graphql`
  query {
    memberPerksImage: file(
      relativePath: { eq: "steak_on_serving_board_crop_center.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1441, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
