import { Box, IconLoadingSpinner, IconProps } from '@butcherbox/freezer'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'

interface LoadingSpinnerProps extends IconProps {
  size?: IconProps['size']
}
export const LoadingSpinner = React.memo(
  ({ size = 'large', ...props }: LoadingSpinnerProps) => (
    <>
      <Box
        data-cy={TEST_ID.LOADING_SPINNER}
        display="flex"
        height="100%"
        justifyContent="center"
        py={60}
        {...props}
      >
        <IconLoadingSpinner size={size} />
      </Box>
    </>
  )
)

export default LoadingSpinner
